<template>
  <b-modal visible centered no-close-on-esc no-close-on-backdrop size="lg">
    <template v-slot:modal-header>
      <h5 class="mb-0 text-uppercase">
        {{ $t(`branchDetails`) }}
      </h5>
    </template>
    <template v-slot:default>
      <b-row>
        <b-col>
          <b-form-group
            label-class="font-weight-bold"
            :label="$t('name')"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="branch.name"
              disabled
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label-class="font-weight-bold"
            :label="$t('address')"
            label-for="address"
          >
            <b-form-input
              id="address"
              v-model="branch.address"
              disabled
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label-class="font-weight-bold"
            :label="$t('city')"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="branch.city"
              disabled
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label-class="font-weight-bold"
            :label="$t('country')"
            label-for="country"
          >
            <b-form-input
              id="country"
              v-model="branch.country"
              disabled
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label-class="font-weight-bold"
            :label="$t('province')"
            label-for="province"
          >
            <b-form-input
              id="province"
              v-model="branch.province"
              disabled
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label-class="font-weight-bold"
            :label="$t('phone')"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="branch.phone"
              disabled
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label-class="font-weight-bold"
            :label="$t('ZIP')"
            label-for="zip"
          >
            <b-form-input id="zip" v-model="branch.zip" disabled></b-form-input>
          </b-form-group>
        </b-col>
        <b-col class="col-6">
          <b-form-group
            label-class="font-weight-bold"
            :label="$t('VAT')"
            label-for="vat"
          >
            <b-form-input id="vat" v-model="branch.vat" disabled></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </template>
    <template v-slot:modal-footer>
      <b-button variant="outline-primary" @click="closeBranchDetailsModal"
        >{{ $t("button.close") }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  emits: ["cancel"],
  props: {
    branchModalState: {
      type: Boolean,
      default: false,
    },
    branch: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closeBranchDetailsModal() {
      this.$emit("cancel");
    },
  },
};
</script>

<style></style>
