<template>
  <div>
    <validation-observer ref="accountForm" v-slot="{ valid }">
      <b-modal
        v-model="state"
        size="lg"
        content-class="rounded-0"
        footer-class="border-top-0 justify-content-start"
        centered
        no-close-on-esc
        no-close-on-backdrop
        @cancel="onCancel"
      >
        <template v-slot:modal-header>
          <h5 class="mb-0 text-uppercase">
            {{ $t(modalTitle) }}
          </h5>
        </template>
        <template v-slot:default>
          <div v-if="branchName" class="mb-3">
            <p class="font-weight-bold mb-1">
              {{ $t("branchName") }}
            </p>
            <b-form-input v-model="branchName" disabled></b-form-input>
          </div>
          <b-form>
            <b-row class="no-gutters">
              <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('page.clientInvoices.invoiceNumber')"
                  label-for="invoiceNumber"
                >
                  <validation-provider
                    immediate
                    :name="$t('page.clientInvoices.invoiceNumber')"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="invoiceNumber"
                      class="input__field"
                      v-model="activeInvoice.number"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('page.clientInvoices.invoiceDate')"
                  label-for="invoiceDate"
                >
                  <validation-provider
                    immediate
                    :rules="{ required: true }"
                    :name="$t('page.clientInvoices.invoiceDate')"
                    v-slot="validationContext"
                  >
                    <b-input-group>
                      <b-form-input
                        id="invoiceDate"
                        v-model="formatedDate"
                        type="text"
                        placeholder="DD/MM/YYYY"
                        autocomplete="off"
                        disabled
                      ></b-form-input>
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="activeInvoice.date"
                          hide-header
                          button-only
                          right
                          aria-controls="invoiceDate"
                          :min="today"
                          :state="getValidationState(validationContext)"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                    <!-- <b-form-datepicker
                      v-model="activeInvoice.date"
                      label-no-date-selected
                      hide-header
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric'
                      }"
                      :min="today"
                      :state="getValidationState(validationContext)"
                    ></b-form-datepicker> -->
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('page.clientInvoices.invoiceType')"
                  label-for="invoiceType"
                >
                  <validation-provider
                    :name="$t('page.clientInvoices.invoiceType')"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-select
                      id="invoiceType"
                      class="clickable"
                      v-model="activeInvoice.type"
                      :state="getValidationState(validationContext)"
                      :options="invoiceTypeOptions"
                      text-field="text"
                      value-field="value"
                    >
                    </b-form-select>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('page.clientInvoices.totalVideo')"
                  label-for="invoiceTotalVideo"
                >
                  <validation-provider
                    immediate
                    :name="$t('page.clientInvoices.totalVideo')"
                    :rules="{ required: true, integer: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="invoiceTotalVideo"
                      class="input__field"
                      v-model="activeInvoice.itemsPurchased"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('page.clientInvoices.price')"
                  label-for="invoicePrice"
                >
                  <validation-provider
                    immediate
                    :name="$t('page.clientInvoices.price')"
                    :rules="{ required: true, amount: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="invoicePrice"
                      class="input__field"
                      v-model="activeInvoice.amount"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </template>
        <template v-slot:modal-footer="{ cancel }">
          <b-row class="no-gutters justify-content-start w-100">
            <b-col v-if="formType === 'EDIT'" class="col-auto">
              <b-button variant="danger" @click="onDelete">
                {{ $t("button.delete") }}
              </b-button>
            </b-col>
            <b-col class="ml-auto col-auto mr-1 mb-1">
              <b-button
                variant="link"
                class="text-dark text-decoration-none"
                @click="cancel"
                >{{ $t("button.cancel") }}</b-button
              >
            </b-col>
            <b-col class="col-auto mr-1 mb-1">
              <b-button @click="onProceed" :disabled="!valid">
                {{ $t(confirmBtnLabel) }}
              </b-button>
            </b-col>
          </b-row>
        </template>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    invoiceModalState: {
      type: Boolean,
      default: false
    },
    formType: {
      type: String,
      default: "NEW"
    },
    invoice: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeInvoice: {},
      branchName : ""
    };
  },
  watch: {
    invoiceModalState: {
      handler: function() {
        if (this.invoiceModalState) this.activeInvoice = this.invoice;
        if (this.invoice.branchId) this.retrieveBranchName();
      },
      immediate: false
    }
  },
  computed: {
    state: {
      get() {
        return this.invoiceModalState;
      },
      set(state) {
        this.$emit("updateInvoiceState", state);
      }
    },
    invoiceTypeOptions() {
      return this.$store.getters["billing/getInvoiceTypeOptions"];
    },
    modalTitle() {
      return this.formType === "NEW"
        ? "page.clientInvoices.createInvoice"
        : "page.clientInvoices.editInvoice";
    },
    confirmBtnLabel() {
      return this.formType === "NEW"
        ? "button.addInvoice"
        : "button.saveChanges";
    },
    today() {
      return dayjs().format("YYYY-MM-DD");
    },
    formatedDate() {
      const dayjsDate = dayjs(this.activeInvoice.date);
      return dayjsDate.isValid() ? dayjsDate.format("DD/MM/YYYY") : "";
    }
  },
  methods: {
    retrieveBranchName(){
      const client = this.$store.getters["clients/getActiveClient"];
      const branch = client.branches.find((branch)=>{
        return branch._id === this.invoice.branchId
      })
      this.branchName = branch.name;
    },
    onProceed() {
      if (this.formType === "NEW")
        this.$emit("createInvoice", this.activeInvoice);
      else if (this.formType === "EDIT")
        this.$emit("updateInvoice", this.activeInvoice);
    },
    onCancel() {
      this.$emit("cancel");
    },
    onDelete() {
      this.$store.commit("confirm/SET_CONFIRM", {
        titleText: "dialog.confirm.deleteInvoiceTitle",
        messageText: "dialog.confirm.deleteInvoiceMessage",
        messageParams: { invoiceNumber: this.activeInvoice.number },
        confirmBtnCallback: this.deleteInvoice,
        type: "DELETE"
      });
    },
    deleteInvoice() {
      this.$emit("deleteInvoice", this.activeInvoice._id);
    },
    getValidationState({ dirty, validated, valid = null, untouched }) {
      if (!untouched) return valid ? null : valid;
      else return null;
    },
    updateDate(date) {
      this.activeInvoice.date = date;
    }
  }
};
</script>

<style scoped>
#invoiceDate {
  background: white;
}
</style>
