<template>
  <div>
    <b-row class="no-gutters align-items-center">
      <b-col class="col-12 col-md-3 body__1">
        {{ fullName }}
      </b-col>
      <b-col class="col-12 col-md-3 text-muted body__2 text-md-center">
        {{ email }}
      </b-col>
      <b-col class="col-12 col-md-3 text-muted body__2 text-md-center">
        {{ subType }}
      </b-col>
      <b-col class="col-12 col-md-3 text-muted body__2 text-md-right">
        {{ branchName }}
      </b-col>
    </b-row>
    <hr class="my-3" />
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    branch: {
      type: Object,
    },
  },
  computed: {
    fullName() {
      return this.user.firstName + " " + this.user.lastName;
    },
    email() {
      return this.user.email;
    },
    subType() {
      return (
        this.user.subType.charAt(0).toUpperCase() + this.user.subType.slice(1)
      );
    },
    branchName() {
      return this.branch?.name ? this.branch.name : "-";
    },
  },
};
</script>

<style></style>
