<template>
  <div>
    <b-row class="no-gutters align-items-center">
      <b-col class="col-8">
        <b-row class="no-gutters">
          <b-col class="col-12 col-md-6 body__1">
            {{ branchName }}
          </b-col>
          <b-col class="col-12 col-md-6 text-muted body__2 text-md-center">
            {{ usersCount }} {{ $t("page.users.title") }}
          </b-col>
        </b-row>
      </b-col>
      <b-col class="col-4">
        <b-button size="sm d-block ml-auto" @click="openNewInvoiceModal">
          {{ $t("button.addInvoice") }}
        </b-button>
        <b-button size="sm mt-2 d-block ml-auto" @click="openNewProjectModal">
          {{ $t("button.createNewProject") }}
        </b-button>
        <b-button
          size="sm mt-2 d-block ml-auto"
          @click="openBranchDetailsModal"
        >
          {{ $t("branchDetails") }}
        </b-button>
      </b-col>
    </b-row>
    <hr class="my-3" />
    <client-project-modal
      v-if="newProjectModalStatus"
      projectType="branch"
      :clientId="clientId"
      :branchId="branchId"
      :branchName="branchName"
      @closeNewProjectModal="closeNewProjectModal"
    />
    <client-invoice-modal
      :invoiceModalState="newInvoiceModalStatus"
      :invoice="invoice"
      @createInvoice="createNewInvoice"
      @cancel="closeNewInvoiceModal"
    />
    <client-branch-modal
      v-if="branchDetailsModalStatus"
      :branchModalState="branchDetailsModalStatus"
      :branch="branch"
      @cancel="closeBranchDetailsModal"
    />
  </div>
</template>

<script>
import ClientProjectModal from "./ClientProjectModal.vue";
import ClientInvoiceModal from "./ClientInvoiceModal.vue";
import ClientBranchModal from "./ClientBranchModal.vue";
export default {
  components: {
    ClientProjectModal,
    ClientInvoiceModal,
    ClientBranchModal,
  },
  props: {
    clientId: {
      type: String,
      required: true,
    },
    branch: {
      type: Object,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      newInvoiceModalStatus: false,
      newProjectModalStatus: false,
      branchDetailsModalStatus: false,
      invoice: {
        number: "",
        amount: null,
        date: "",
        itemsPurchased: null,
        type: "",
        branchId: this.branch._id,
      },
    };
  },
  computed: {
    branchId() {
      return this.branch._id;
    },
    branchName() {
      return this.branch.name;
    },
    usersCount() {
      return this.users.length;
    },
  },
  methods: {
    openNewInvoiceModal() {
      this.newInvoiceModalStatus = true;
    },
    closeNewInvoiceModal() {
      this.newInvoiceModalStatus = false;
    },
    openNewProjectModal() {
      this.newProjectModalStatus = true;
    },
    closeNewProjectModal() {
      this.newProjectModalStatus = false;
    },
    openBranchDetailsModal() {
      this.branchDetailsModalStatus = true;
    },
    closeBranchDetailsModal() {
      this.branchDetailsModalStatus = false;
    },
    openLoader(loaderSettings = {}) {
      this.$store.commit("loader/SET_LOADER", loaderSettings);
    },
    closeLoader() {
      this.$store.dispatch("loader/close");
    },
    createNewInvoice(invoice) {
      this.openLoader();
      this.$store
        .dispatch("billing/createInvoice", {
          clientId: this.clientId,
          invoice,
        })
        .then(() => {
          this.newInvoiceModalStatus = false;
        })
        .catch(() => {})
        .finally(() => {
          this.closeLoader();
        });
    },
  },
};
</script>

<style></style>
